import React from "react";
import Grid from "@mui/material/Grid";
import { baseUrl, fetchApi } from "../utils/fetchApi";
import Container from "@mui/material/Container";
import dynamic from "next/dynamic";

const CarouselCard = dynamic(() => import("../components/CarouselCard"));

const Home = ({ properties }: any) => {
  const [cards] = React.useState(properties);
  if (!cards.length) {
    return null;
  }
  return (
    <>
      <Container maxWidth={false}>
        <Grid container rowSpacing={3} columnSpacing={3}>
          {cards?.map((properties: any) => {
            return (
              <Grid item key={properties.id} xs={12} sm={4} md={4} lg={2}>
                <CarouselCard location={properties} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export async function getStaticProps() {
  const properties = await fetchApi(
    `${baseUrl}/searchProperty?category=TAB_8225&totalRecords=36`
  );

  return {
    props: {
      properties: properties?.data,
    },
  };
}

export default Home;
